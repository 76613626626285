html,
body {
  height: 100%;
}

#root {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
}

.content {
  flex: 1 0 auto;
}

section {
  margin-top: 120px;
}

.slider-div {
  height: 800px;
}

.slider-div-desktop{
  height: 900px;
}

.swiper {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;

  /* Center slide text vertically */
  display: -webkit-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  -webkit-box-align: center;
  -ms-flex-align: center;
  -webkit-align-items: center;
  align-items: center;
}

.swiper-slide-img-div {
  position: relative;
  width: 100%;
  height: 95%;
}

.swiper-slide-container {
  width: 100%;
  height: 100%;
}

.swiper-slide-text {
  font-weight: 600;
  margin: 0px;
}

.swiper-slide-image-container {
  height: 98%;
  width: 100%;
}

.btn-link {
  width: 100px;
}

.my-btn-wide {
  width: 129.5px;
}

.project-col {
  height: auto;
}

.card-title a {
  color: #212529;
}

.attribution-link {
  color: #212529;
}

.col-margin-top-1 {
  margin-top: 10px;
}



@media screen and (max-width: 768px) {
  .slider-div {
    height: 700px;
  }

  .swiper-slide-img-div {
    height: 94%;
  }

  li.nav-item a {
    padding-left: 0px !important;
  }

  .col-margin-top-1 {
    margin-top: 10px !important;
  }
}

@media screen and (max-width: 1200px) {
  .col-margin-top-1 {
    margin-top: 10px !important;
  }
}

.icon-text-nginx {
  margin-top: -5px;
}
.icon-img-nginx {
  margin-top: -2px;
}
